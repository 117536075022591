import Cookies from 'js-cookie';
import md5 from 'md5';
import {navigate} from 'gatsby';
import moment from 'moment';

const CookieDomains = ['37.186.119.181', 'localhost', '.hexomatic.com', '192.168.0.132'];

// TODO: REMOVE THIS!!!!!!!!
// TMP FIX FOR COOKIES
setTimeout(() => {
  // @ts-ignore
  if (
    global.window &&
    global.window.location &&
    global.window.location.hostname &&
    global.window.location.hostname.indexOf('hexomatic.com') > -1
  ) {
    Cookies.remove('__session', {
      domain: undefined,
    });
  }
}, 100);

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);
export const setRawCookie = (name: string, value: string) =>
  CookieDomains.map(domain => {
    Cookies.set(name, value),
      {
        expires: 30,
        domain: domain,
        sameSite: 'Strict',
      };
  });

export const getCookies = (): any => {
  let cookiesStr: string | undefined = undefined;
  let cookies = {};
  for (let domain of CookieDomains) {
    cookiesStr = Cookies.get('__session', {domain: domain});
    if (cookiesStr) {
      try {
        cookies = JSON.parse(decodeURIComponent(cookiesStr));
      } catch (e) {}
    }
  }

  return cookies;
};

export const setCookie = (key: string, value: string | null | boolean, expires?: number) => {
  CookieDomains.map(domain => {
    Cookies.set('__session', JSON.stringify({...getCookies(), [key]: value}), {
      expires: expires ? expires : 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const removeCookiePolicyNew = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicyNew = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};

export const setImpactClickIdCookie = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('__irClickId', value, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const setImpactMediaPartnerIdCookie = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('__mediaPartnerId', value, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const removeImpactClickIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__irClickId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactMediaPartnerIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__mediaPartnerId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setDocumentReferrer = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('document_referrer', value, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const removeDocumentReferrer = () => {
  CookieDomains.map(domain => {
    Cookies.remove('document_referrer', {
      expires: 30,
      domain: domain,
    });
  });
};

export const getCookie = (key: string): string => getCookies()[key];
export const removeCookie = (key: string) => {
  const cookies = getCookies();
  delete cookies[key];
  CookieDomains.map(domain => {
    Cookies.remove('__session', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setCookieDownloadIgnore = () => setCookie('download-panel', 'closed');
export const getCookieDownloadIgnore = () => getCookie('download-panel');

export const setHintPopupPerTool = (toolName: string, val: string) =>
  // @ts-ignore
  global.window && localStorage && localStorage.setItem(toolName, val);

export const getHintPopupPerTool = (toolName: string) =>
  // @ts-ignore
  global.window && localStorage && localStorage.getItem(toolName);

export const setCaptchaResponseToken = (token: string) =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.setItem('toolsCaptchaResponseToken', token);

export const getCaptchaResponseToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.getItem('toolsCaptchaResponseToken');

export const deleteCaptchaResponseToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.removeItem('toolsCaptchaResponseToken');

export const setToolsToken = (token: string) =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.setItem('toolsSessionToken', token);

export const getToolsToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.getItem('toolsSessionToken');

export const deleteToolsToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.removeItem('toolsSessionToken');

export const setToolNameHistory = (val: string) =>
  global.window && localStorage && localStorage.setItem('selectedTool', val);
export const getToolNameHistory = (): string =>
  (global.window && localStorage && localStorage.getItem('selectedTool')) || 'true';
export const removeToolNameHistory = () => localStorage.removeItem('selectedTool');

export const setShowModalonExit = (show: string) => localStorage.setItem('show-modal-onexit', show);
export const getShowModalonExit = (): string => localStorage.getItem('show-modal-onexit') || 'true';
export const removeShowModalonExit = () => localStorage.removeItem('show-modal-onexit');

export const setShowDemoModalonExit = (show: string) => localStorage.setItem('show-demo-modal-onexit', show);
export const getShowDemoModalonExit = (): string => localStorage.getItem('show-demo-modal-onexit') || 'true';
export const removeShowDemoModalonExit = () => localStorage.removeItem('show-demo-modal-onexit');

export const setLTDHeader = (show: string) => localStorage.setItem('ltd-header', show);
export const getLTDHeader = (): string => localStorage.getItem('ltd-header') || 'true';
export const removeLTDHeader = () => localStorage.removeItem('ltd-header');

export const setLTDPromo = (show: string) => localStorage.setItem('promo81535', show);
export const getLTDPromo = (): string => localStorage.getItem('promo81535') || '';
export const removeLTDPromo = () => localStorage.removeItem('promo81535');

export const setShowModalonClick = (show: string) => localStorage.setItem('show-modal-onclick', show);
export const getShowModalonClick = (): string => localStorage.getItem('show-modal-onclick') || 'true';
export const removeShowModalonClick = () => localStorage.removeItem('show-modal-onclick');

export const setUserExists = (show: string) => localStorage.setItem('user-exists', show);
export const getUserExists = (): string => localStorage.getItem('user-exists') || 'false';
export const removeUserExists = () => localStorage.removeItem('user-exists');

export const setUserToken = (token: string) => setCookie('user-token', token);
export const setUserHash = (hash: string) => setCookie('user-hash', hash);
export const getUserToken = (): string | undefined => getCookie('user-token');
export const getUserHash = (): string | undefined => getCookie('user-hash');
export const removeUserToken = () => setCookie('user-token', null);
export const removeUserHash = () => setCookie('user-hash', null);

export const setUserEmail = (email: string) => setCookie('user-email', email);
export const getUserEmail = (): string | undefined => getCookie('user-email');
export const removeUserEmail = () => setCookie('user-email', null);

export const setUserName = (name: string) => setCookie('user-name', name);
export const getUserName = (): string | undefined => getCookie('user-name');
export const removeUserName = () => setCookie('user-name', null);

export const setNewUser = (isNew: boolean) => setCookie('new-user', isNew);
export const getNewUser = (): string | undefined => getCookie('new-user');
export const removeNewUser = () => removeCookie('new-user');

export const setCookiePolicy = () => setCookie('user', 'registered');
// @ts-ignore
export const getCookiePolicy = () => (!global.window ? 'registered' : getCookie('user'));

export const setDeviceKey = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('device_key', hash, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

export const setRefreshToken = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('ref_token', hash, {
      expires: 30,
      domain: domain,
      sameSite: 'Strict',
    });
  });
};

// export const getGravatar = (email: string, size?: number) => {
//   size = size || 80;
//   return 'https://www.gravatar.com/avatar/' + md5(email) + '?s=' + size + '&d=monsterid';
// };

export const toggleLastURL = () => {
  if (window.history.length === 1) {
    navigate('/');
  } else {
    window.history.back();
  }
};

export const toggleCloseLoginModalURL = () => {
  navigate('/');
};

export const gotToWithReplace = (pathName: string) => {
  window.history.replaceState({}, '', pathName);
  if (pathName === '/login') {
    navigate(pathName, {state: {goBack: true}});
  } else {
    navigate(pathName);
  }
};
export const cleanURL = (url: string) => {
  return url?.toLowerCase().replace(' ', '-');
};

export const eventStop = (e: any) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const logOut = () => {
  setUserExists('true');
  removeUserToken();
  removeUserHash();
  removeUserEmail();
  removeUserName();
  removeNewUser();
  if (!getUserName()) {
    navigate('/');
  }
};

export const makeValidHref = (baseUrl: string): string => {
  let linkURL = baseUrl;
  if (baseUrl.indexOf('://') === -1) {
    linkURL = `http://${linkURL}`;
  }

  return linkURL || baseUrl;
};

export const sendUserDataToExtension = async (msg?: any, refToken?: string, deviceKey?: string) => {
  const extensionIds: string[] = [
    'gaeglheibddedbjiehaeeacoldaiimkl', //!  The ID of the extension we want to talk to.
    'jmpflegmbfmkimhdhfbjhpnncahdmodo',
    'pdafbekgencanldlcjdagfjmihjjcoef',
    'nhibigcgaipebhhkncfgblijenpjhgpp',
    'bmnlpebnhjbjgbdmiaalflcjjfkfbpgf',
    'jjphfeeonaidkhddfiihhiemoaabpaah',
    'fiebpkfinhoigbcfenmclogdkaligfbl',
    'jldcheckmiaadnhakbpdjhcdddeiglcc',
  ];
  //@ts-ignore
  global.window &&
    //@ts-ignore
    global.window.chrome &&
    //@ts-ignore
    global.window.chrome.runtime &&
    extensionIds.forEach(async (item: string) => {
      try {
        //@ts-ignore
        await global.window.chrome.runtime.sendMessage(item, {cookie: msg || null, refToken, deviceKey});
      } catch {
        return;
      }
    });

  (function() {
    document.dispatchEvent(
      new CustomEvent('extension-cookie-event', {
        detail: {cookie: msg || null, refToken, deviceKey},
      }),
    );
  })();
};
export const toFixedNumber = (num: number) => {
  return String(num).slice(String(num).indexOf('.')).length > 2
    ? String(num)
        .slice(0, String(num).indexOf('.') + 3)
        .includes('.00')
      ? String(num)
          .slice(0, String(num).indexOf('.') + 3)
          .replace('.00', '')
      : String(num).slice(0, String(num).indexOf('.') + 3)
    : num;
};

export const getDateAndTime = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)');
};

export const getParsedDateAndTimeGMT = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment(parsedDate).format('MMM DD, YYYY HH:mm:ss') : '';
};

export const cleanShortURL = (url: string) => {
  const cleanUrl = url
    .replace('https://', '')
    .replace('http://', '')
    .replace(url[url.length - 1] === '/' ? '/' : '', '');
  return cleanUrl && cleanUrl.length > 50 ? cleanUrl.slice(0, 50) : cleanUrl;
};

export const setHotJarUser = (
  userId: number,
  pricing_status: string,
  pricing_plan: string | null,
  pricing_interval: string | null,
  premium_credits_pricing_package: string | null,
) => {
  window &&
    //@ts-ignore
    window.hj &&
    //@ts-ignore
    window.hj('identify', userId, {
      userId,
      pricing_status,
      pricing_plan,
      pricing_interval,
      premium_credits_pricing_plan: premium_credits_pricing_package,
    });
};
export const removePackageInfo = () => {
  localStorage.removeItem('fromLogonOrRegistrationPage');
  localStorage.removeItem('checkout-data');
};


export const getPromoDate = () => {
  // Get current date and time in GMT
  const currentDate = new Date();
  const currentDay = currentDate.getUTCDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
  const currentHour = currentDate.getUTCHours();

  // Calculate time remaining until the next Friday 11 AM GMT
  let daysUntilFriday = (5 - currentDay + 7) % 7; // 5 is the day index for Friday
  if (currentDay === 5 && currentHour >= 7) {
    // If it's already Friday after 11 AM, consider the next Friday
    daysUntilFriday += 7;
  }

  // Calculate the next Friday's date
  const nextFriday = new Date(currentDate);
  nextFriday.setUTCDate(currentDate.getUTCDate() + daysUntilFriday);
  nextFriday.setUTCHours(7, 0, 0, 0); // Set time to 11 AM GMT

  return nextFriday.toUTCString();
};

