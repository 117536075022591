import React, {FC, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Location} from '@reach/router';
import {HelmetHeaderProps} from '../types';

export default ({}: FC<HelmetHeaderProps>) => {
  const current = '';

  const workflowsList = '';

  const [headerData] = useState<{title: string; path: string; description?: string; image?: string}[]>([
    {
      title: 'Customer support contacts | Hexomatic',
      description: 'Customer support contacts - content change detection and alerting solution',
      path: '/contact',
    },
    {
      title: 'Software Integrations',
      description: 'List of 3rd party integrations with Hexomatic',
      path: '/software-integrations',
    },
    {
      title: 'Get workflow monitoring notifications in Telegram',
      description: 'Connect with Telegram to receive the monitoring notifications about your workflows.',
      path: '/telegrambot',
    },
    {
      title: 'Get workflow monitoring notifications in Slack',
      description: 'Connect with Slack to receive the monitoring notifications about your workflows.',
      path: '/slackbot',
    },
    {
      title: 'Get workflow monitoring notifications in Discord',
      description: 'Connect with Discord to receive the monitoring notifications about your workflows.',
      path: '/discord',
    },
    {
      title: 'Import, export or synchronize data in your workflow via Google Sheets.',
      description: 'Connect with Google to import, export or synchronize data in your workflow.',
      path: '/google-sheets',
    },
    {
      title: 'Hexomatic Integration with Zapier',
      description: 'Connect with Zapier to receive the monitoring notifications about your workflows.',
      path: '/zapier',
    },
    {
      title: 'Hexomatic Integration with Pabbly Connect',
      description: 'Integrate Pabbly Connect to receive the monitoring notifications about your workflows.',
      path: '/pabbly-connect',
    },
    {
      title: 'Hexomatic Integration with Microsoft Teams',
      description: 'Connect with Microsoft Teams to receive the monitoring notifications about your workflows.',
      path: '/microsoft-teams',
    },
    {
      title: 'Hexomatic Integration with KonnectzIT',
      description: 'Connect with KonnectzIT to receive the monitoring notifications about your workflows.',
      path: '/konnectzit',
    },
    {
      title: 'Hexomatic Integration with Make, formerly Integromat',
      description: 'Connect with Make to receive the monitoring notifications about your workflows.',
      path: '/make',
    },
    {
      title: 'Hexomatic Integration with Integrately',
      description: 'Connect with Integrately to receive the monitoring notifications about your workflows.',
      path: '/integrately',
    },
    {
      title: 'Integrate Google with Hexomatic | Hexomatic',
      description: 'Connect your Google account to Hexomatic. ',
      path: '/google',
    },
    {
      title: 'Connect webhook URL to your account | Hexomatic',
      description: 'Connect webhook URL to your account and get JSON formatted data of the workflow. ',
      path: '/webhook',
    },
    {
      title: 'Post in WordPress at scale | Hexomatic',
      description:
        'Post in WordPress at scale from any data source from your workflow with our Hexomatic WordPress automation.',
      path: '/wordpress',
    },
    {
      title: 'Get data from any SQL database inside your workflow | Hexomatic',
      description: 'Tap into SQL databases from your own applications inside Hexomatic to access data at scale.',
      path: '/sql-database',
    },
    {
      title: 'Export or sync your data to Google Drive | Hexomatic',
      description: 'Hexomatic lets you save any file from your workflow to your Google Drive account on autopilot.',
      path: '/google-drive',
    },
    {
      title: 'Import data via Google Sheets | Hexomatic',
      description:
        'Hexomatic lets you use Google sheets right inside your workflow as an input for scraping receipts or automations.',
      path: '/google-sheets-import',
    },
    {
      title: 'Export and synchronize data in your workflow via Google Sheets | Hexomatic',
      description: 'Hexomatic enables you to automatically export or synchronize your data to Google Sheets.',
      path: '/google-sheets-export-sync',
    },
    {
      title: 'Cookie Policy | Hexomatic',
      description: 'This Cookie Policy describes how and why HEXACT use cookies',
      path: '/cookie-policy',
    },
    {
      title: 'FAQ | Hexomatic',
      description:
        'Do you have any questions regarding Hexomatic services and tools? Find answers on all your questions or contact us.',
      path: '/faq',
    },
    {
      title: 'Pricing Packages | Hexomatic',
      description:
        'Check all the features and benefits of our plans. Hexomatic allows you to choose from, Silver, Gold and Enterprise pricing packages.',
      path: '/pricing',
    },
    {
      title: 'API Documentation',
      description: 'This page provides API documentation for workflows',
      path: '/api-documentation',
    },
    {
      title: 'Discover Tech Stack API documentation',
      description: 'This page provides API documentation for Discover Tech Stack',
      path: '/automation/discover-tech-stack/api-documentation',
    },
    {
      title: 'Screenshot capture API documentation',
      description: 'This page provides API documentation for Screenshot capture',
      path: '/automation/create-screenshot/api-documentation',
    },
    {
      title: 'Discover WHOIS API documentation',
      description: 'This page provides API documentation for Discover WHOIS',
      path: '/automation/discover-whois/api-documentation',
    },
    {
      title: 'Discover Profile API documentation',
      description: 'This page provides API documentation for Discover Profile',
      path: '/automation/discover-profile/api-documentation',
    },
    {
      title: 'Privacy Policy | Hexomatic',
      description:
        'Your privacy is very important to us and we have created this privacy statement in order to demonstrate our commitment to you.',
      path: '/privacy-policy',
    },
    {
      title: 'Hexomatic - reset password',
      description: '',
      path: '/reset-password',
    },
    {
      title: 'Hexomatic Public Roadmap | Development Plans',
      description:
        "We are excited to announce our Hexomatic roadmap! This is a place for you to see what's coming next and how any feedback from users like yourself can help shape it.",
      path: '/roadmap',
    },
    {
      title: 'About Hexomatic | How Hexomatic Works',
      description:
        'Hexomatic is a no-code, work automation platform that enables you to leverage the most complex AI services to automate and delegate time consuming tasks.',
      path: '/about',
    },
    {
      title: 'Hexomatic Use Cases | Growth Hacking Use Cases',
      description:
        'See growth hacking use cases with Hexomatic. Find automated solutions for your e-commerce business, sales and marketing teams, finance or investment  research teams.',
      path: '/use-cases',
    },
    {
      title: 'Meet the Hexomatic team',
      description:
        'The ones who see things differently, working tirelessly behind the scenes so you can change the world.',
      path: '/meet-the-team',
    },
    {
      title: 'Growth hacking playbook',
      description: '',
      path: '/get-our-growth-hacking-playbook',
    },
    {
      title: 'Ready made automations for sales, marketing & research tasks',
      description:
        'Take advantage of the true power of the cloud with 1-click access to data, analysis, and automation powered by AI. Instantly create your ideal workflow.',
      path: '/automations',
    },
    {
      title: 'Terms of Service | Hexomatic',
      description:
        'These Terms of Service contain an Arbitration Agreement that may impact your rights to use the courts to resolve disputes.',
      path: '/terms-of-use',
    },
    {
      title: '',
      description: '',
      path: '/home',
    },
    {
      title: '',
      description: '',
      path: '/404',
    },
    {
      title: 'Web Scraping and Workflow Automation Made Easy | Hexomatic',
      description:
        'Tap into the internet as your own data source with our web scraper and automate 100+ sales, marketing, or research tasks on autopilot.',
      path: '/',
    },
    {
      title: 'Hexomatic – GDPR',
      // description: 'Register your free account and get full access to the website tools.',
      path: '/gdpr',
    },
    {
      title: 'Hexomatic – Choose your plan',
      // description: 'Register your free account and get full access to the website tools.',
      path: '/checkout',
    },
    {
      title: 'Media Kit | Hexomatic logo and colors',
      description:
        'We share guidelines to help our customers use Hexomatic logo and colors without having to negotiate legal agreements',
      path: '/media-kit',
    },
    {
      title: 'Become a Hexomatic affiliate partner',
      description: 'Become a Hexomatic affiliate partner and earn 20% commission on every sale and renewal.',
      path: '/affiliate',
    },
    {
      title: 'Premium credits',
      description: 'Premium credit add-on',
      path: '/premium-credits',
    },
    //tools
    {
      title: 'Growth hacking automation tools - Hexomatic',
      description:
        'Find contact details, perform tech stack analysis or tap into AI services with our easy-to-use growth hacking tools, powered by Hexomatic.',
      path: '/tools',
    },
    {
      title: 'Online Text to Speech Generator | Realistic AI Voice',
      description:
        "Hexomatic's online text to speech converter turns any text into the most natural-sounding speech and allows you to save it to .mp3 format. 40+ languages available",
      path: '/tools/text-to-speech',
    },
    {
      title: 'Tech Stack Checker | Free Tech Stack Analyzer',
      description:
        "Find out any website technology stack and perform instant tech stack checks  in minutes! Automate your website's competitors analysis.",
      path: '/tools/tech-stack-analyzer',
    },
    {
      title: 'Whois domain lookup | Free Domain Whois Lookup',
      description:
        'Use our whois domain lookup tool to discover domain expiry, ownership, contact, registrar, and nameserver information for any domain or IP.',
      path: '/tools/whois-domain-lookup',
    },
    {
      title: 'Grammar and Spell Checker | Hexomatic',
      description:
        "Use Hexomatic's grammar and spell checker for any web page! This tool allows you to catch typos, spelling, and grammar mistakes in seconds.",
      path: '/tools/grammar-spell-checker',
    },
    {
      title: 'QR code generator | Free tool',
      description:
        'Generate QR codes in seconds and use it on stationary, posters, merchandise or product packaging. This will also benefit to drive traffic and increase sales',
      path: '/tools/qr-code-generator',
    },
    {
      title: 'Word Count Checker | Web Page Content Checker Tool',
      description:
        "Explore Hexomatic's word count checker tool, find any web page keywords and keywords' count, see the average reading time of the page for free",
      path: '/tools/word-count',
    },
    {
      title: 'Screen Capture | Multi Device Website Screenshot Capture',
      description: 'Our free online tool will provide you with screenshot captures for any device screen size.',
      path: '/tools/screenshot-capture',
    },
    {
      title: 'Accessibility Audit | Free Website Accessibility Audit',
      description:
        'Perform any page accessibility audit in seconds. Get recommendations for making the web more accessible to people with disabilities.',
      path: '/tools/accessibility-audit',
    },
    //Automation category
    {
      title: 'Scrape Data with Hexomatic',
      description:
        'Scrape data from any website including Amazon, Google news, Google maps, Tripadvisor, and more via our 1-click scraping automations. No code required.',
      path: '/automations/scraping',
    },
    {
      title: 'Notifications automations | Hexomatic',
      description:
        'Automate the process of getting notifications in one place! Get all your notifications on your Hexomatic dashboard.',
      path: '/automations/notifications',
    },
    {
      title: 'Data-transformations automations | Hexomatic',
      description:
        'Our data transformation automations are great tools to help you with your every day workflow: cryptocurrency and currency converters, text transformations and more.',
      path: '/automations/data-transformations',
    },
    {
      title: 'SEO automations | Hexomatic',
      description:
        'SEO is a vital part of pushing your website and getting organic traffic and keywords rankings. Use our SEO automations to save time and get better results.',
      path: '/automations/seo',
    },
    {
      title: 'Research automations | Hexomatic',
      description:
        "Hexomatic's research automations are designed to help business owners find needed info about seller, product and website.",
      path: '/automations/research',
    },
    {
      title: 'Actions automations | Hexomatic',
      description:
        'Actions automations are effective and useful for either business, or personal use. We are constantly releasing new actions automations.',
      path: '/automations/actions',
    },
    {
      title: 'Integrations automations | Hexomatic',
      description: 'Hexomatic already has 6 integrations that can help you get needed data just inside your workflow.',
      path: '/automations/integrations',
    },
    {
      title: 'Translation automations | Hexomatic',
      description:
        'Hexomatic has integrated with DeepL and Google Translate. You can perform translations right from your Hexomatic workflow.',
      path: '/automations/translation',
    },
    {
      title: 'Validation automations | Hexomatic',
      description:
        'Perform email validations via easyDMARC right from your Hexomatic workflow. Check malicious urls and define the best strategy for your business.',
      path: '/automations/validation',
    },
    {
      title: 'Audits automations | Hexomatic',
      description:
        'Perform website audit automations to check your pages for accessibility issues. We also provide grammar and spelling audits.',
      path: '/automations/audits',
    },
    {
      title: 'AI services automations | Hexomatic',
      description:
        'AI automations are trending the most nowadays. Hexomatic offers a great list of ai automations designed to scale up your business and save your time.',
      path: '/automations/ai-services',
    },

    //Automation types
    {
      title: 'Premium automations | Hexomatic',
      description:
        'Get our Bronze package and use all our premium automations to facilitate your work flow and get the best results.',
      path: '/automations/premium-automations',
    },
    // {
    //   title: 'Automations/Crowdsourced automations',
    //   description: 'Crowdsourced automations',
    //   path: '/automations/crowdsourced-automations',
    // },
    {
      title: 'Built-in Automations | Hexomatic',
      description:
        'Find out your preferred built-in automation in Hexomatic. We offer wide variety of built-in automations for your business.',
      path: '/automations/built-in-automations',
    },

    //Automation combined type/category

    ///Premium-automations
    {
      title: 'Premium automations | Scraping | Hexomatic',
      description:
        "Hexomatic's premium automations include scraping services that can help you save time and amplify your business.",
      path: '/automations/premium-automations/scraping',
    },
    {
      title: 'Premium automations | Notifications | Hexomatic',
      description:
        "Hexomatic's premium automations include notification services that can help you save time and get all your needed notifications in one place.",
      path: '/automations/premium-automations/notifications',
    },
    {
      title: 'Premium automations | Data transformations | Hexomatic',
      description:
        "Hexomatic's premium automations include data transformations that can help you save time and amplify your business.",
      path: '/automations/premium-automations/data-transformations',
    },
    {
      title: 'Premium automations | SEO | Hexomatic',
      description:
        "Hexomatic's premium SEO automations can help you save time and succeed in getting higher rankings and traffic.",
      path: '/automations/premium-automations/seo',
    },
    {
      title: 'Premium automations | Research | Hexomatic',
      description:
        "Hexomatic's premium automations include research services that can help you save time and amplify your business.",
      path: '/automations/premium-automations/research',
    },
    {
      title: 'Premium automations | Actions | Hexomatic',
      description:
        "Hexomatic's premium actions automations can help you save time and succeed in getting higher rankings and traffic.",
      path: '/automations/premium-automations/actions',
    },
    {
      title: 'Premium automations | Integrations | Hexomatic',
      description:
        "Hexomatic's premium automations include itegrations with various tools! This will help you save time and amplify your business.",
      path: '/automations/premium-automations/integrations',
    },
    {
      title: 'Premium automations | Translation | Hexomatic',
      description:
        "Hexomatic's premium automations include itegrations with translation tools! This will help you save time and amplify your business.",
      path: '/automations/premium-automations/translation',
    },
    {
      title: 'Premium automations | Validation | Hexomatic',
      description:
        "Hexomatic's premium automations include validation services that can help you save time and amplify your business.",
      path: '/automations/premium-automations/validation',
    },
    {
      title: 'Premium automations | Audits | Hexomatic',
      description:
        "Hexomatic's premium actions automations can help you save time on time consuming audits and have the info you need in minutes.",
      path: '/automations/premium-automations/audits',
    },
    {
      title: 'Premium automations | AI services | Hexomatic',
      description: "Hexomatic's premium AI automations will save your time and amplify your business.",
      path: '/automations/premium-automations/ai-services',
    },
    {
      title: 'Premium automations | SEO | Hexomatic',
      description: 'Premium automations - SEO',
      path: '/automations/premium-automations/seo',
    },
    ///Cowdsourced-automations
    // {
    //   title: 'Automations/Crowdsourced automations/Scraping',
    //   description: 'Crowdsourced automations - Scraping',
    //   path: '/automations/crowdsourced-automations/scraping',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/Notifications',
    //   description: 'Crowdsourced automations - Notifications',
    //   path: '/automations/crowdsourced-automations/notifications',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/Data transformations',
    //   description: 'Crowdsourced automations - Data transformations',
    //   path: '/automations/crowdsourced-automations/data-transformations',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/SEO',
    //   description: 'Crowdsourced automations - SEO',
    //   path: '/automations/crowdsourced-automations/seo',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/Research',
    //   description: 'Crowdsourced automations - Research',
    //   path: '/automations/crowdsourced-automations/research',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/Actions',
    //   description: 'Crowdsourced automations - Actions',
    //   path: '/automations/crowdsourced-automations/actions',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/Integrations',
    //   description: 'Crowdsourced automations - Integrations',
    //   path: '/automations/crowdsourced-automations/integrations',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/Translation',
    //   description: 'Crowdsourced automations - Translation',
    //   path: '/automations/crowdsourced-automations/translation',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/Validation',
    //   description: 'Crowdsourced automations - Validation',
    //   path: '/automations/crowdsourced-automations/validation',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/Audits',
    //   description: 'Crowdsourced automations - Audits',
    //   path: '/automations/crowdsourced-automations/audits',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/AI services',
    //   description: 'Crowdsourced automations - AI services',
    //   path: '/automations/crowdsourced-automations/ai-services',
    // },
    // {
    //   title: 'Automations/Crowdsourced automations/SEO',
    //   description: 'Crowdsourced automations - SEO',
    //   path: '/automations/crowdsourced-automations/seo',
    // },

    ///Built-in automations
    {
      title: 'Built-in automations | Scraping | Hexomatic',
      description: 'Automations - Built-in automations - Scraping',
      path: '/automations/built-in-automations/scraping',
    },
    {
      title: 'Built-in automations | Notifications | Hexomatic',
      description: 'Automations - Built-in automations - Notifications',
      path: '/automations/built-in-automations/notifications',
    },
    {
      title: 'Built-in automations | Data transformations | Hexomatic',
      description: 'Automations - Built-in automations - Data transformations',
      path: '/automations/built-in-automations/data-transformations',
    },
    {
      title: 'Built-in automations | SEO | Hexomatic',
      description: 'Automations - Built-in automations - SEO',
      path: '/automations/built-in-automations/seo',
    },
    {
      title: 'Built-in automations | Research | Hexomatic',
      description: 'Automations - Built-in automations - Research',
      path: '/automations/built-in-automations/research',
    },
    {
      title: 'Built-in automations | Actions | Hexomatic',
      description: 'Automations - Built-in automations - Actions',
      path: '/automations/built-in-automations/actions',
    },
    {
      title: 'Built-in automations | Integrations | Hexomatic',
      description: 'Automations - Built-in automations - Integrations',
      path: '/automations/built-in-automations/integrations',
    },
    {
      title: 'Built-in automations | Translation | Hexomatic',
      description: 'Automations - Built-in automations - Translation',
      path: '/automations/built-in-automations/translation',
    },
    {
      title: 'Built-in automations | Validation | Hexomatic',
      description: 'Automations - Built-in automations - Validation',
      path: '/automations/built-in-automations/validation',
    },
    {
      title: 'Built-in automations | Audits | Hexomatic',
      description: 'Automations Built-in automations Audits',
      path: '/automations/built-in-automations/audits',
    },
    {
      title: 'Built-in automations | AI services | Hexomatic',
      description: 'Automations - Built-in automations - AI services',
      path: '/automations/built-in-automations/ai-services',
    },
    {
      title: 'Built-in automations | SEO | Hexomatic',
      description: 'Automations - Built-in automations - SEO',
      path: '/automations/built-in-automations/seo',
    },

    //Public workflows
    {
      title: 'Automate Workflows - Ready Made Template Library',
      description:
        'Save hundreds of hours with ready-made workflows to find new leads, scrape data ,perform website audits and deploy SEO strategies in minutes.',
      path: '/ready-made-workflow-library',
    },
    {
      title:
        'Page 2/5 - Workflow library - Ready made templates to automate sales, marketing and research tasks | Hexomatic',
      description: '',
      path: '/ready-made-workflow-library?page=2',
    },
    {
      title:
        'Page 3/5 - Workflow library - Ready made templates to automate sales, marketing and research tasks | Hexomatic',
      description: '',
      path: '/ready-made-workflow-library?page=3',
    },
    {
      title:
        'Page 4/5 - Workflow library - Ready made templates to automate sales, marketing and research tasks | Hexomatic',
      description: '',
      path: '/ready-made-workflow-library?page=4',
    },
    {
      title:
        'Page 5/5 - Workflow library - Ready made templates to automate sales, marketing and research tasks | Hexomatic',
      description: '',
      path: '/ready-made-workflow-library?page=5',
    },
    {
      title: 'Ready made workflows for Sales teams',
      description:
        'Simplify and scale your sales prospecting with our ready-made workflow templates designed for sales teams! Find fresh prospects and enrich your data in minutes.',
      path: '/ready-made-workflow-library/sales',
    },
    {
      title: 'Ready made workflows for Research teams',
      description:
        'Maximize research and data analysis efficiency with our workflow templates. Conduct audits, extract data, analyze, and utilize advanced AI for image labeling and OCR.',
      path: '/ready-made-workflow-library/research',
    },
    {
      title: 'Ready made workflows for Marketing teams',
      description:
        'Save time and expand the reach of your marketing and SEO efforts using our pre-designed workflow templates. Implement complete SEO and marketing strategies within minutes.',
      path: '/ready-made-workflow-library/marketing',
    },
    {
      title: 'Hexomatic and ChatGPT automations - workflow library',
      description:
        'Get our ready-made workflow libraries with ChatGPT and perform get the data you want in seconds! Use web scraping and ChatGPT and get the best resuts.',
      path: '/ready-made-workflow-library/chatgpt',
    },
    {
      title: 'Run an backlink research campaign in minutes - Hexomatic workflow',
      description:
        'This workflow enables you to specify a list of website domains and perform in-depth backlink analysis to find the best referring domains linking to your competitors.',
      path: '/ready-made-workflow-library/research-backlink-partners-&-contact-details-via-list',
    },
    {
      title: 'Crawl your website and create an XML Sitemap - Hexomatic workflow',
      description:
        'XML Sitemaps help search engines discover your content and index it. Generate XML sitemap files in minutes with no coding or software skills with Hexomatic.',
      path: '/ready-made-workflow-library/crawl-your-website-&-create-an-xml-sitemap',
    },
    {
      title: 'Research backlink partners via keyword - Hexomatic Workflow',
      description:
        'This workflow enables you to specify a list of keywords and perform in-depth backlink analysis to find the best referring domains linking to your competitors.',
      path: '/ready-made-workflow-library/research-backlink-partners-&-contact-details-via-keyword',
    },
    {
      title: 'Resource page link building - Hexomatic Workflow',
      description:
        'Find curated lists that can link to your own website. This workflow looks for existing resource pages for a specific keyword on Google and finds contact details.',
      path: '/ready-made-workflow-library/find-resources-page-seo-opportunities',
    },
    {
      title: 'Ready made workflows for Marketing teams',
      description:
        'Automate and scale time-consuming marketing and SEO strategies with our ready-made workflow templates. Deploy entire marketing strategies featuring SEO data, machine translation, spelling audits, accessibility checks, and more in minutes.',
      path: '/ready-made-workflow-library/marketing?page=2',
    },
    {
      title: 'Discover influencers, bloggers, or publishers - Hexomatic workflow',
      description:
        'Find influencers, bloggers, or publishers to promote your business in minutes. This workflow looks for existing reviews on Google and finds contact details on autopilot.',
      path: '/ready-made-workflow-library/discover-influencers-contact-details',
    },
    {
      title: 'Perform site-wide WCAG 2.1 accessibility audits on autopilot - Hexomatic workflow',
      description:
        'Run an in-depth WCAG 2.1 accessibility audit on any website. This workflow creates branded pdf reports you can share with your team, prospects, or clients.',
      path: '/ready-made-workflow-library/perform-a-site-wide-accessibility-audit',
    },
    {
      title: 'Perform WCAG 2.1 accessibility audits in bulk - Hexomatic workflow',
      description:
        'Run an in-depth WCAG 2.1 accessibility audit on any URL in bulk. This workflow creates branded pdf reports for your landing pages to share with your team.',
      path: '/ready-made-workflow-library/audit-a-list-of-pages-for-accessibility-issues',
    },
    {
      title: 'Perform WCAG 2.1 accessibility audits on Google search - Hexomatic workflow',
      description:
        'Run an in-depth WCAG 2.1 accessibility audit on the top ranking websites in Google for any keyword. This workflow is ideal to create branded pdf reports.',
      path: '/ready-made-workflow-library/audit-businesses-ranking-on-google-for-accessibility-issues',
    },
    {
      title: 'Create an XML sitemap by crawling your website - Hexomatic workflow',
      description:
        'XML Sitemaps help search engines discover your content and index it. This workflow crawls an entire website and generates an XML sitemap file.',
      path: '/ready-made-workflow-library/crawl-a-website-and-generate-an-xml-sitemap',
    },
    {
      title: 'Discover email addresses for a list of websites - Hexomatic workflow',
      description:
        'Provide a list of domains and Hexomatic will find publicly available email addresses and validate those.',
      path: '/ready-made-workflow-library/discover-email-addresses-for-a-list-of-websites',
    },
    {
      title: 'Find backlink opps based on keywords to improve your SEO rankings - Hexomatic workflow',
      description:
        'Automate backlink research at scale by reverse-engineering the pages that link to the top ranking websites for any keyword on Google.',
      path: '/ready-made-workflow-library/find-all-the-pages-that-link-to-the-top-ranking-websites',
    },
    {
      title: "Find all your competitors' backlinks & improve SEO - Hexomatic workflow",
      description:
        'Automate backlink research at scale by reverse-engineering the pages that link to a list of domains or URLs. This workflow creates a comprehensive report containing the best backlinks and their metrics.',
      path: '/ready-made-workflow-library/find-all-the-pages-that-link-to-your-competitors',
    },
    {
      title: 'Research referring domains of top ranking websites for any keyword - Hexomatic workflow',
      description:
        'This workflow allows to automate your backlink research at scale by reverse-engineering the referring domains that link to the top ranking websites for any keyword on Google.',
      path: '/ready-made-workflow-library/find-domains-linking-to-the-top-ranking-sites-for-a-keyword',
    },
    {
      title: 'Find the referring domains that link to your competitors on autopilot',
      description:
        "Automate your backlink research by reverse-engineering your competitors referring domains. This workflow provides competitors' best backlinks and their metrics.",
      path: '/ready-made-workflow-library/find-domains-that-link-to-your-competitors',
    },
    {
      title: 'Resource page SEO link building on autopilot - Hexomatic workflow',
      description:
        'Find curated lists that can link to your own website. This workflow looks for existing resource pages for a specific keyword on Google and finds contact details.',
      path:
        '/ready-made-workflow-library-library/find-email-addresses-and-contact-details-for-any-domain-in-b/find-resources-page-seo-opportunities',
    },
    {
      title: 'Extract meta tags for a list of URLs on autopilot - Hexomatic workflow',
      description:
        'Improve your CTR by extracting meta tags at scale in minutes. This workflow extracts the meta data, og and twittergraph tags for a list of URLs on autopilot.',
      path: '/ready-made-workflow-library/extract-meta-tags-for-a-list-of-urls',
    },
    {
      title: 'Bulk check tech stack data for a list of URLs - Hexomatic workflow',
      description:
        'Discover the tech stack used on a list of URLs including what type of technology and third-party services are currently in use.',
      path: '/ready-made-workflow-library/discover-tech-stack-for-a-list-of-websites',
    },
    {
      title: 'Find local businesses or B2B leads via Google Maps - Hexomatic workflow',
      description:
        "Find local businesses or B2B prospects for any industry and geographic location on autopilot with Hexomatic's workflow.",
      path: '/ready-made-workflow-library/find-b2b-leads-from-google-maps',
    },
    {
      title: 'Check pages for display issues on autopilot - Hexomatic workflow',
      description:
        'Create screenshots in different device sizes at scale to find responsive design issues or archive visual screenshots for research or outreach.',
      path: '/ready-made-workflow-library/check-pages-and-create-screenshots-as-mobile-devices',
    },
    {
      title: 'Perform competitor research on multiple websites on autopilot - Hexomatic workflow',
      description:
        'Accelerate your competitor research with this ready made workflow combining WHOIS, tech stack and meta tags reports for a list of websites.',
      path: '/ready-made-workflow-library/competitor-research-from-a-list-of-domains',
    },
    {
      title: 'Find contact details for any business - Hexomatic workflow',
      description:
        'Find contact details for a list of website in minutes. This workflow looks for contact details on each website and on the web, then validates each email.',
      path: '/ready-made-workflow-library/find-email-addresses-and-contact-details-for-any-domain-in-bulk',
    },
    {
      title: 'Monitor competing sellers on Amazon at scale - Hexomatic workflow',
      description:
        'Find competing sellers and their offers for a list of Amazin ASIN numbers on autopilot to stay competitive and catch listing highjackers.',
      path: '/ready-made-workflow-library/check-competitors-competing-for-amazon-listings',
    },
    {
      title: 'Transcribe a list of images using OCR at scale - Hexomatic workflow',
      description:
        'Extract text from a list of images using AI and OCR, perfect for transcribing menus, signs or image based pdf documents.',
      path: '/ready-made-workflow-library/transcribe-images-in-bulk',
    },
    {
      title: 'Verify a List of Email Addresses - Ready-Made Workflows',
      description:
        'Wondering if the email addresses you plan to contact are still working? Use our ready-made workflow for bulk verifying a list of addresses in minutes!',
      path: '/ready-made-workflow-library/verify-a-list-of-email-addresses-(premium)',
    },
    {
      title: 'Scrape Google Search to a Spreadsheet - Ready-Made Workflows',
      description:
        'Our ready-made workflow will allow you to scrape Google search results in minutes and get the biggest info box you ever need with no-coding skills.',
      path: '/ready-made-workflow-library/scrape-google-search-to-a-spreadsheet',
    },
    {
      title: 'Scrape Google Maps to a Spreadsheet - Ready-Made Workflows',
      description:
        'With Hexomatic, you can scrape any Google Maps data into a structured spreadsheet! Ideal for researching local businesses at scale.',
      path: '/ready-made-workflow-library/scrape-google-maps-to-a-spreadsheet',
    },
    {
      title: 'Check any website for spelling or grammar mistakes on autopilot - Hexomatic workflow',
      description:
        'Run an in-depth spelling and grammar audit on any website. This workflow creates branded pdf reports you can share with your team, prospects, or clients.',
      path: '/ready-made-workflow-library/website-seo-spelling-and-grammar-audit',
    },
    {
      title: 'Check any website for broken links on autopilot - Hexomatic workflow',
      description:
        'Run an in-depth broken link audit on any website. This workflow provides a detailed CSV or Google Sheets report you can share with your team, prospects, or clients.',
      path: '/ready-made-workflow-library/website-broken-links-audit',
    },
    {
      title: 'Verify a list of email addresses on autopilot - Hexomatic workflow',
      description:
        'Email validation verifies if email addresses are current and still working. With our email validation workflow you can improve your email deliverability.',
      path: '/ready-made-workflow-library/verify-a-list-of-email-addresses',
    },
    {
      title: 'Find guest post opps to improve SEO rankings - Hexomatic workflow',
      description:
        'Find guest post opportunities for any keyword or industry including email and social media profile contact details for each publisher.',
      path: '/ready-made-workflow-library/find-seo-guest-post-opportunities',
    },
    {
      title: 'Find affiliates to promote your products or services - Hexomatic workflow',
      description:
        'Find prospects that will promote your affiliate marketing program. This workflow looks for existing affiliate reviews on Google and finds contact details.',
      path: '/ready-made-workflow-library/find-affiliates-for-your-product-or-service',
    },
    {
      title: 'Create icebrakers for Google Search Leads with Hexomatic Easily',
      description:
        'Use ChatGPT and Hexomatic and create icebrakers for Google Search Leads in second! Scale up your workflow!',
      path: '/ready-made-workflow-library/create-ice-breakers-for-google-search-leads',
    },
    {
      title: 'Research any product or category usimng web scraping and ChatGPT',
      description:
        'Get detailed analysis and review on the desired product via our market analisys automation using web scraping and ChatGPT.',
      path: '/ready-made-workflow-library/market-analysis',
    },
    {
      title: 'Find recently funded tech companies using Google News, web scraping and ChatGPT',
      description:
        'Great opportunity to find newly funded tech companies! Our worfkflow uses google news, web scraping and ChatGPT to reveal newly funded companies.',
      path: '/ready-made-workflow-library/find-&-research-recently-funded-companies',
    },
    {
      title: 'Use Amazon as a data source for your ecommerce product listings',
      description:
        'Create ecommerce product listings! Just specify a list of product names, and the workflow will create unique product titles, descriptions, an FAQ by researching data from Amazon.',
      path: '/ready-made-workflow-library/create-ecommerce-product-listings-using-amazon-as-a-data-source',
    },
    {
      title: 'Find leads from Google maps and create icebrakers',
      description:
        'Create engaging ice brakers for the leads found on Google Maps with ChatGPT and Hexomatic on autopilot in seconds.',
      path: '/ready-made-workflow-library/create-ice-breakers-for-google-maps-leads',
    },
    {
      title: 'Create your 2025 social media calednar in minutes',
      description:
        'Get an entire social media content calendar for a year in minutes! Simply replace the prompt text with information about your company and get your calendar!',
      path: '/ready-made-workflow-library/create-a-2025-social-media-calendar',
    },
    {
      title: 'Scrape Amazon Search Results - Amazon Search Results Scraper',
      description:
        'Scrape Amazon product search results in seconds with no coding skills. Amazon search results scraper will extract the products data and save your time!',
      path: '/ready-made-workflow-library/scrape-amazon-search-results',
    },
    {
      title: 'Scrape Articles from a URL - Article Scraper from URL List',
      description:
        'Extract content and title data with article scraper! Save tons of time and scrape content from a list of URLs in seconds with no coding skills.',
      path: '/ready-made-workflow-library/scrape-articles-from-a-list-of-urls',
    },
    {
      title: 'Check URL Status in Bulk Ready-made Workflow Template',
      description:
        'Check the URL status in bulk with our ready-made workflow automation template. Reveal broken links at scale in minutes.',
      path: '/ready-made-workflow-library/check-the-url-status-of-a-list-of-urls',
    },
    {
      title: 'Extract URLs from an XML Sitemap - Ready Made Workflow Template',
      description: 'Our ready-made workflow will help you extract all the Urls from the sitemap in seconds.',
      path: '/ready-made-workflow-library/extract-all-the-urls-from-an-xml-sitemap',
    },
    {
      title: 'Capture Screenshots of URLs Ready-made Workflow Template',
      description:
        'Capture screenshots from a list of urls with our ready-made workflow automation template. This automation captures screenshots using 8 different device sizes at scale.',
      path: '/ready-made-workflow-library/capture-screenshots-of-a-list-of-urls',
    },
    {
      title: 'Mobile-Friendly Audit for a List of Urls Automation Workflow Template',
      description:
        'Our mobile audit ready-made workflow will automatically audit a list of URLs for mobile-friendliness at scale.',
      path: '/ready-made-workflow-library/audit-a-list-of-urls-for-mobile-friendly-issues',
    },
    {
      title: 'Malicious URLs Checker Ready-Made Workflow Template',
      description:
        'Safeguard your website from malicious urls with our ready-made workflow template. This automation analyzes pages for known malware or phishing URLs.',
      path: '/ready-made-workflow-library/check-a-list-of-urls-for-known-malware-and-malicious-urls',
    },
    {
      title: 'Categorize Websites Automatically - Ready-made Workflow Template',
      description: 'Categorize and classify a list of websites automatically with our ready-made workflow template.',
      path: '/ready-made-workflow-library/categorize-a-list-of-website-urls',
    },
    {
      title: 'Sentiment Analysis Ready-Made Automation Workflow Template',
      description:
        'Perform sentiment analysis in seconds at scale with our ready-made workflow template! Get setup in minutes.',
      path: '/ready-made-workflow-library/perform-sentiment-analysis-on-a-list-of-urls',
    },
    {
      title: 'Automate Gemini AI Tasks Ready-Made Automation Workflow Template',
      description:
        'Automate Google Gemini tasks in bulk! Summarize, improve, craft content, analyze, create social media posts, and more in bulk using our ready-made Gemini workflow template.',
      path: '/ready-made-workflow-library/automate-gemini-ai-tasks',
    },
    {
      title: 'Phone Number Scraper Ready-Made Workflow Template',
      description:
        'Our ready-made workflow will automatically scrape phone numbers from a list of URLs. Great workflow to expand your network!',
      path: '/ready-made-workflow-library/scrape-phone-numbers-from-a-list-of-urls',
    },
    {
      title: 'Logo and Favicon Finder Ready-Made Workflow Template',
      description:
        'Our ready-made logo and favicon finder workflow template will automatically extract all the logos and favicons in bulk from a list of URLs.',
      path: '/ready-made-workflow-library/find-logos-and-favicons-for-a-list-of-urls',
    },
    {
      title: 'Yelp Scraper Workflow Template - Scrape Yelp in Seconds',
      description:
        'Scrape Yelp search results with our ready-made workflow automation template. Get market trends, reviews, business details in seconds.',
      path: '/ready-made-workflow-library/scrape-yelp-search-results',
    },
    {
      title: 'Google Shopping Scraper - Get Product Details At Scale Workflow Template',
      description:
        'Get product details from Google Shopping at scale using our Google Shopping scraper automation workflow template. Compare product prices, reviews and other more.',
      path: '/ready-made-workflow-library/scrape-google-shopping',
    },
    {
      title: 'Google News Scraper - Scrape Google News Data in Bulk Workflow Template',
      description:
        'Scrape Google News data in bulk! Easily find relevant information and extract it in seconds with our ready-made Google News scraper automation workflow template.',
      path: '/ready-made-workflow-library/scrape-google-news',
    },
    {
      title: 'Automate ChatGPT Tasks in Bulk - ChatGPT Workflow Template',
      description:
        'Automate ChatGPT tasks in bulk! Summarize, improve, craft content, analyze, create social media posts, and more in bulk using our ready-made ChatGPT workflow template.',
      path: '/ready-made-workflow-library/automate-chatgpt-tasks',
    },
    {
      title: 'Find all the URLs and pages from a website to CSV format Ready-made Workflow Template',
      description:
        'Crawl entire websites to capture all their URLs in a handy CSV or Google Sheet format using this ready made workflow template.',
      path: '/ready-made-workflow-library/find-all-the-urls-and-pages-from-a-website-to-csv-format',
    },
    {
      title: 'Scrape WHOIS data for a list of URLs',
      description:
        'Find out when a domain name was purchased, its status (whether it’s been registered), when it is due to be renewed, and contact details for the organization and domain name administrators.',
      path: '/ready-made-workflow-library/scrape-whois-data-for-a-list-of-urls',
    },
    {
      title: 'Crop images at scale',
      description:
        'Crop images at scale with our Hexomatic workflow template, ideal for online retailers focusing on product details, and manage large volumes of images efficiently.',
      path: '/ready-made-workflow-library/crop-images-at-scale',
    },
    {
      title: 'Compress images at scale',
      description:
        'Compress images in bulk, ideal for online stores optimizing product images for faster loading times while automating the process to save time and reduce manual effort.',
      path: '/ready-made-workflow-library/compress-images-at-scale',
    },
    {
      title: 'Convert image formats at scale',
      description:
        'Convert image formats at scale with our Hexomatic workflow template, ideal for standardizing product images across different formats to ensure compatibility with various platforms.',
      path: '/ready-made-workflow-library/convert-image-formats-at-scale',
    },
    {
      title: 'Resize and compress images at scale',
      description:
        'Effortlessly resize images at scale with our Hexomatic workflow template, ideal for online stores and social media.',
      path: '/ready-made-workflow-library/resize-and-compress-images-at-scale',
    },
    {
      title: 'Translate articles at scale',
      description:
        'Automate and streamline the translation of multiple articles with our Hexomatic workflow template, powered by DeepL.',
      path: '/ready-made-workflow-library/translate-articles-at-scale',
    },
    {
      title: 'Back to school promo | Hexomatic',
      description: '',
      path: '/back-to-school-promo',
    },
    {
      title: 'Limited time offer | Hexomatic',
      description:
        "Get Hexomatic's life-time deal with our pricing-promo for a limited time offer! Automate your time-consuming tasks with special price!",
      path: '/pricing_promo',
      image: 'https://hexomatic.com/pricing_promo.jpg',
    },
    {
      title: 'Limited time offer | Hexomatic',
      description:
        "Get Hexomatic's life-time deal with our pricing-promo for a limited time offer! Automate your time-consuming tasks with special price!",
      path: '/promo39025',
      image: 'https://hexomatic.com/pricing_promo.jpg',
    },
    {
      title: 'Limited time offer | Hexomatic',
      description:
        "Celebrating our 5-year journey Buy or Upgrade for just $99",
      path: '/5-year-journey',
    },
    {
      title: 'Limited time offer | Hexomatic',
      description:
        "Get Hexomatic's life-time deal with our pricing-promo for a limited time offer! Automate your time-consuming tasks with special price!",
      path: '/promo30421',
      image: 'https://hexomatic.com/pricing_promo.jpg',
    },
    {
      title: 'Special Offer | Hexomatic',
      description:
        'Save 50% Limited Time Deal! Automate your time-consuming tasks with special price!',
      path: '/pricing-promo',
    },
    {
      title: 'Limited time offer | Hexomatic',
      description:
        "Get Hexomatic's life-time deal with our pricing-promo for a limited time offer! Automate your time-consuming tasks with special price!",
      path: '/fusebasePromo',
      image: 'https://hexomatic.com/pricing_promo.jpg',
    },
    {
      title: 'Halloween Promo | Hexomatic',
      description: 'Get ready to have your socks scared off with our spooktacular Halloween promo!',
      path: '/halloween_promo97700',
      image: 'https://hexomatic.com/halloween_promo.jpg',
    },
    {
      title: 'Private promo | Hexomatic',
      description: '',
      path: '/gold-annual-promo19903',
      image: 'https://hexomatic.com/pricing_promo_annual.jpg',
    },
    {
      title: 'The Everything Bundle - Software Cyber Monday Deal 2023',
      description:
        'Get the best 2023 Cyber Monday deal for a bundle of AI automations and tools! Save up to 65% off Hexact’s entire suite of products.',
      path: '/EVERYTHING-BF-2023',
      image: 'https://hexomatic.com/Cyber-Monday-Deal.jpg',
    },
    {
      title: 'The Everything Bundle - Spring Break Promo',
      description:
        'Get the best Spring Break Promo deal for a bundle of AI automations and tools! Save up to 65% off Hexact’s entire suite of products.',
      path: '/EVERYTHING-SB-2024',
      image: 'https://hexomatic.com/Spring-Break-Deal.jpg',
    },
    {
      title: 'Holiday 2022 Pricing Promo | SaaS Products',
      description:
        'Learn about all the pricing promos and hot deals for our workflow automation products! Hexomatic, Hexospark, Hexowatch and Hexometer offer you hot deals!',
      path: '/holiday2022',
      image: 'https://hexomatic.com/Holidays-2022.png',
    },
    {
      title: 'Premium credits flash promo',
      description: 'Access all our data sources with a special 33% lifetime discount on premium credits.',
      path: '/premiums_promo',
      image: 'https://hexomatic.com/premium-credits-offer.jpg',
    },
    {
      title: 'Get 3 Years Premium Credits Promo - Web Scraping Automations',
      description:
        "Get 3 years of Hexomatics' premium credits for automating your tasks and web scraping for only $159! Access premium data sources including ChatGPT, Gemini, Google, Amazon and more.",
      path: '/premiums_promo_3y',
      image: 'https://hexomatic.com/premium-credits-offer-3y.jpg',
    },
    {
      title: 'Get 3 Years Premium Credits Promo - Web Scraping Automations',
      description:
        "Get 3 years of Hexomatics' premium credits for automating your tasks and web scraping for only $159! Access premium data sources including ChatGPT, Gemini, Google, Amazon and more.",
      path: '/premiums_promo_39025',
      image: 'https://hexomatic.com/premium-credits-offer-3y.jpg',
    },
    // {
    //   title: 'Lifetime offer | Hexomatic',
    //   description:
    //     'Due to popular demand, we are opening up 500 seats to our legendary lifetime deal for 48H only.',
    //   path: '/promo81052',
    //   image: 'https://hexomatic.com/limited-time-offer.jpg',
    // },
    {
      title: 'Checkout | Hexomatic',
      description: '',
      path: '/pricing/checkout',
    },
    {
      title: 'Enterprise | Hexomatic',
      description: '',
      path: '/enterprise',
    },
    {
      title: 'Hexomatic Login | Login To Your Account',
      description: 'Login to your dashboard on Hexomatic and start automating your time-consuming tasks now.',
      path: '/login',
    },
    {
      title: 'Register for Hexomatic Account',
      description:
        'Register in Hexomatic and start saving time on your daily tasks with our 150+ automations and web scrapers.',
      path: '/registration',
    },
  ]);

  const generateHeaderMenuItem = () => {
    const headers = [...headerData];

    current &&
      current.map((item: any) => {
        item &&
          headers.push({
            title: item.meta_title,
            path: `/automation/${item.url}`,
            description: item.meta_title,
          });
      });

    const getCleanPath = (name: string) => {
      const singleWorkflowPath = name.toLowerCase();
      const cleanPath = singleWorkflowPath.replace(/ /g, '-');
      return `/ready-made-workflow-library/${cleanPath}`;
    };

    workflowsList &&
      workflowsList.map((item: any) => {
        item &&
          headers.push({
            title: item.meta_title,
            path: getCleanPath(item.name),
            description: item.description,
            image: item.image,
          });
      });
    return headers;
  };

  return (
    <Location>
      {({location}) => {
        const item = generateHeaderMenuItem().find((data: any) => {
          if (location.pathname[location.pathname.length - 1] === '/' && location.pathname !== '/') {
            return data.path === location.pathname.substr(0, location.pathname.length - 1);
          } else if (location.pathname === '/') {
            return data.path === location.pathname;
          } else if (data.path === '/get-our-growth-hacking-playbook' && location.pathname.includes(data.path)) {
            return data;
          } else {
            return location.pathname === data.path || location.pathname === data.path + '/';
          }
        });
        return (
          item && (
            <Helmet key={item.title} title={item.title}>
              <html lang="en" />
              {!location.pathname.includes('/reset-password') &&
                item.path !== '/reset-password' &&
                item.path !== '/404' && (
                  <script type="text/javascript">
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-WSDXCCK');`}
                  </script>
                )}
              {location.pathname.includes('/faq') && (
                <script type="application/ld+json">
                  {`{
                    "@type": "Question",
                    "name": "How are automation credits counted?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Automation credits are used when we perform an action (for example fetch a page, extract emails, create a screenshot, get WHOIS data, Extract social media links automatically, discover techstack, etc.)

                We also provide free internal actions once a page has been retrieved or an automation task has run, for example to select fields to capture, perform date/text/number formatting, conditional logic etc."
                    }
                  },{
                    "@type": "Question",
                    "name": "Can I use Hexomatic to perform Social media automations?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexomatic can be used to scrape most websites, but is not designed to perform social media scraping and interactions such as automated following, liking, connection requests, or other actions which can get users banned from social media websites such as Instagram, Facebook or LinkedIn.

                We recently added Twitter data using the official API and YouTube video scraping."
                    }
                  },{
                    "@type": "Question",
                    "name": "Which websites can I scrape using Hexomatic?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexomatic features a built in website scraper that you can program to extract text, numbers or images at scale with pagination support.

                Some websites employ more advanced countermeasures which may block our requests, our scraping engine comes with built-in rotating IP's that helps prevent this.

                For websites that employ more advanced countermeasures we've released an optional residential proxy add-on using our premium credit system."
                    }
                  },{
                    "@type": "Question",
                    "name": "What is the difference between Hexomatic and Hexowatch?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexowatch is a change monitoring platform that alerts you when visual, content, keyword, source code, price, availability, backlink, API or sitemap changes are detected.

                Hexomatic is a work automation platform that enables you to capture data, run automations and leverage 3rd party services and crowdsourcing to automate time consuming tasks at scale."
                    }
                  },{
                    "@type": "Question",
                    "name": "What is the difference between Hexomatic and app integration automation platforms?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Application integration platforms connect data from one application or service to another. For example, these types of applications enable you to send data from your eCommerce shop when an order is made to your email marketing platform and works like a data pipe.

                Hexomatic on the other hand enables you to create custom website scraping recipes, perform custom actions, leverage different pre-built automations, 3rd party services and manage human tasks using a no-code, visual point and click workflow.

                Best of all you can connect Hexomatic to Zapier, Pabbly Connect, Integrately and Syncspider inside your workflows to send alerts or data from your automation to a wide range of apps and services."
                    }
                  },{
                    "@type": "Question",
                    "name": "What are premium automations?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexomatic provides two types of actions and automations you can use in your workflows:

                Built-in automations and actions are created by the Hexomatic team and consume automation credits from your monthly quota.
                Premium automations provide access to external 3rd party services (for example providing machine translation, proxy or AI services)
                Premium automations are available via premium credits, an optional add-on starting at just $9.99/m. These credits are not included in any of our paid or LTD plans."
                    }
                  },{
                    "@type": "Question",
                    "name": "What is the difference between 10 and unlimited workflows between SIlver and Gold plans?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Within Hexomatic you will be able to create different workflows that you can run manually or schedule. We recommend the Gold plan if you would like to schedule more than 10 workflows at a time or run more than 10 concurrent workflows at a time."
                    }
                  },{
                    "@type": "Question",
                    "name": "Can Hexomatic scrape data from password protected pages?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexomatic can scrape data from password protected pages. When creating a recipe for a webpage you can use “Type” and “Click” actions to fill out forms and login to access password protected pages. You can then scrape pages as normal as long as it is not against the TOS of the target website."
                    }
                  },{
                    "@type": "Question",
                    "name": "Will Hexomatic enable me to scrape this … website?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We can’t provide specifics as to which websites will be able to be scrapable, but our scraping engines provide IP rotation as standard, and for more complex situations where countermeasures are used you can use our optional residential proxy add-on which will use premium credits to help in these instances."
                    }
                  },{
                    "@type": "Question",
                    "name": "Can I copy the product descriptions on the site of my supplier and translate them?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, the idea is to enable you to chain together different automations, so for example in this usecase you could:

                Create a scraper for your supplier to keep a CSV or Google sheet updated with the latest products and info
                Then have an automation to translate product name/description into a new field for each row then you can chain another automation to send you a Slack message when ready"
                    }
                  },{
                    "@type": "Question",
                    "name": "What are AI features used for?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexomatic provides access to a wide range of AI powered automations including:

                AI Text To Speech premium automation. It enables text to be converted into speech sounds imitative of the human voice via Google Text-to-Speech.

                AI Audio Transcription premium automation by using speech recognition capabilities automatically converts audio files into text.

                AI Document OCR premium automation can recognize text from PDF documents by Google Vision AI.

                AI Image OCR premium automation detects and extracts texts from any image.

                AI Sentiment analysis premium automation provides sentiment analysis for any text fragment or page based on positive, negative or neutral sentiment at scale.

                AI image labeling premium automation intelligently detects and extracts image labels via Google Vision AI.

                AI image safety performs image safety checks via Google Vision AI to check for adult themes.

                AI logo detection premium automation detects product logos within an image via Google Vision AI."
                    }
                  },{
                    "@type": "Question",
                    "name": "How do automation credits work?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Each plan gets an automation credit quota that renews each month. Automation credits are used when we perform an action (for example fetch a page, extract emails, create a screenshot, get WHOIS data, Extract social media links automatically, discover techstack, etc.

                We also provide free internal actions once a page has been retrieved or an automation task has run, for example to select fields to capture, perform date/text/number formatting, conditional logic etc.

                To illustrate imagine you have a product supplier with 100 product pages.

                If you created a scraping recipe to scrape these, each page request would consume 1 automation credit (even if you capture the title, product name, description, price for each product). So this would consume 100 credits.

                If you wanted to automatically create a screenshot of each page, that would be a credit each time, so 100 more.

                Then if you save this to a CSV or Google sheet it will consume 1 additional automation credit. Sending a link to the sheet or a request via Zapier or Pabbly Connect would be a credit per call as well."
                    }
                  },{
                    "@type": "Question",
                    "name": "Do premium credits roll over?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Premium credits provide access to managed automations and a wide range of 3rd party services including residential proxies, translation and AI services on a discounted and pay per use basis saving $$$ in subscription fees.

                Premium automations are continually monitored and updated by our team and some 3rd party services such as residential proxies incur substantial subscription fees in order for Hexomatic to bring down the cost of access to our users.

                In order to provide these benefits we are unable to transfer the unused premium credits to the next month. In case of monthly subscription you can subscribe, pause and restart it at any time."
                    }
                  },{
                    "@type": "Question",
                    "name": "Are payments for Premium credits refundable?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Premium credits are not refundable."
                    }
                  },{
                    "@type": "Question",
                    "name": "What integrations Hexomatic provide?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexomatic provides integrations with:

                Google Sheets
                Discord
                Telegram
                Slack
                Microsoft Teams
                Integromat
                SQL databases
                Zapier
                Integrately
                Pabbly Connect
                KonnectzIT
                WordPress"
                    }
                  },{
                    "@type": "Question",
                    "name": "Do you have any use-cases to illustrate how Hexomatic works?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can find some of our use-cases here:https://hexomatic.com/use-cases"
                    }
                  },{
                    "@type": "Question",
                    "name": "Can I stack more than 10X?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you can purchase multiple stacks (multiple times), automation credits cap is 1 mln."
                    }
                  },{
                    "@type": "Question",
                    "name": "What is a data input?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Hexomatic workflows consist of two core parts: A starting point and a series of automations or scraping recipes that perform work tasks.

                The Data Input automation enables you to provide data as a starting point for your workflow.

                We currently support the following types of data inputs:

                Upload CSV, TXT, JSON. PDF, Image or Audio files (for example containing URLS or documents)
                External CSV. TXT. JSON files hosted remotely hosted externally
                Paste as list of inputs - Lets you provide a list of URLS or keywords and iterate through each in your workflow
                Paste text - Lets you copy paste a piece of text ideal for single translations.

                You can then add additional automations to perform different work tasks for each URL, Keyword or data provided in the data input."
                    }
                  }]
                }`}
                </script>
              )}
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#03b8ff" /> */}
              <link rel="canonical" href={`https://hexomatic.com${location.pathname}`} />
              <meta name="msapplication-TileColor" content="#03b8ff" />
              <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
              <meta name="theme-color" content="#ffffff" />
              <meta name="msapplication-config" content="/browserconfig.xml" />

              <meta name="description" content={item.description} />
              <meta property="og:type" content="website" />
              <meta property="og:image" content={item.image || 'https://hexomatic.com/Home-page-illustrations.jpg'} />
              <meta property="og:url" content={`https://hexomatic.com${location.pathname}`} />
              <meta property="og:title" content={item.title} />
              <meta property="og:description" content={item.description} />
              <meta property="og:locale" content={'en_US'} />
              <meta name="twitter:image" content={item.image || 'https://hexomatic.com/Home-page-illustrations.jpg'} />
              <meta name="twitter:title" content={item.title} />
              <meta name="twitter:description" content={item.description} />
              <meta name="twitter:card" content={'summary_large_image'} />
              <meta name="twitter:site" content={'@hexactinc'} />
            </Helmet>
          )
        );
      }}
    </Location>
  );
};
